import { IToast, IToastLevel } from '@/hooks/useToast';
import { DataFileStatus } from '@/types/datafile';
import { DataFileSocketMessage, getSocketMessageTaskID, isDataFileEvent } from '@/types/socket';
import { SocketSubscriber } from '@/store/modules/socket';
import { IDialogComponent } from '@/hooks/useDialog';
import { t } from '@/i18n';
import {
  CHECK_TASK_RESULT_RETRIES_COUNT, isToast, ProgressContext, ProgressPayload,
  UNKNOWN_TOAST_RESULT,
} from '@/store/modules/tasksProgress';

export async function startExportTask(
  context: ProgressContext,
  { task, restore }: ProgressPayload,
) {

  const {
    commit, state, dispatch, rootState, getters,
  } = context;

  const progressbars = [{
    key: 'progress',
    current: 0,
    max: 0,
    label: 'exchange.export.toast.progress',
  }];
  task.progressbars = progressbars;

  const ERROR_TOAST_DEFAULT: IToast = {
    label: 'pureLabel',
    params: {
      label: 'Ошибка создания отчета',
    },
    level: IToastLevel.danger,
    duration: 4000,
  };

  let checkTaskStatusIntervalId;
  let unsubSocket: () => void;
  if (!restore) {
    commit('setTask', task);
  }
  const storedTask = state.tasks[task.key];

  const existProgressToast = rootState.layout.toasts.find((t) => t.id === task.toastId);

  let closeProgressToast: () => void;

  const result: false|string|IToast|undefined = await new Promise(async (resolve) => {
    let lastSocketMessageTime: number;
    const onClose = () => {
      dispatch('cancelTask', task.uuid);
    };
    if (!existProgressToast) {
      closeProgressToast = await dispatch('layout/showToast', {
        message: 'exchange.import.form.toast.submit.message',
        progressbars: getters.taskProgressbars[storedTask.key],
        level: IToastLevel.info,
        duration: null,
        onClose,
      }, { root: true });
      commit(
        'setTaskToastId',
        {
          taskKey: storedTask.key,
          toastId: (closeProgressToast as unknown as { id: string }).id,
        },
      );
    } else {
      commit('layout/updateToastById', {
        id: storedTask.toastId,
        progressbars: getters.taskProgressbars[storedTask.key],
        onClose,
      }, { root: true });
      closeProgressToast = () => dispatch('layout/closeToastById', task.toastId, { root: true });
    }
    if (restore) {
      let checkTaskResultCounter = 0;
      const checkTaskResult = async () => {
        checkTaskResultCounter += 1;
        if (checkTaskResultCounter > CHECK_TASK_RESULT_RETRIES_COUNT && !lastSocketMessageTime) {
          resolve(UNKNOWN_TOAST_RESULT);
        } else {
          const taskResult = await dispatch('fetchTaskResult', task.id);
          if (taskResult) {
            const { status, result } = taskResult;
            if (status === DataFileStatus.FAILED) resolve(false);
            else if (status === DataFileStatus.PROCESSED) resolve(result.url);
          }
        }
      };
      checkTaskStatusIntervalId = setInterval(() => {
        checkTaskResult();
      }, 10000);
    }

    let file: string;

    unsubSocket = await dispatch('socket/subscribe', {
      condition: (payload) => isDataFileEvent(payload)
        && getSocketMessageTaskID(payload) === task.uuid,
      handler: (payload) => {
        lastSocketMessageTime = new Date().getTime();
        if ('error_message' in payload.data) {
          dispatch('layout/showToast', {
            label: 'pureLabel',
            message: 'pure',
            params: {
              // @ts-ignore
              label: payload.data.description,
              // @ts-ignore
              message: payload.data.error_message,
            },
            level: IToastLevel.danger,
          });
        }

        // @ts-ignore
        if (payload.data?.obj?.url) {
          // @ts-ignore
          file = payload.data?.obj?.url;
        }
        if (payload.data.progress) {
          const copiedTask = state.tasks[task.key];
          copiedTask.progressbars[0].current = payload.data.progress.step;
          copiedTask.progressbars[0].max = payload.data.progress.total;
          commit('updateTask', copiedTask);
          commit('layout/updateToastById', {
            id: copiedTask.toastId,
            progressbars: getters.taskProgressbars[copiedTask.key],
          }, { root: true });
        }
        if (payload.data.done) {
          resolve(file);
        }
      },
    } as SocketSubscriber<DataFileSocketMessage>, { root: true });
  });

  if (result !== undefined) {
    if (result) {
      if (typeof result === 'string') {
        dispatch('layout/showDialog', {
          component: IDialogComponent.file,
          addInRoute: false,
          payload: {
            title: t('exchange.export.dialog.title'),
            url: result,
            withPreview: true,
            withCopy: true,
          },
        }, { root: true });
      }
    } else if (isToast(result)) {
      dispatch('layout/showToast', UNKNOWN_TOAST_RESULT, { root: true });
    } else {
      dispatch('layout/showToast', ERROR_TOAST_DEFAULT, { root: true });
    }
  }
  commit('removeTask', task);
  // @ts-ignore
  unsubSocket?.();
  // @ts-ignore
  closeProgressToast?.();
  clearInterval(checkTaskStatusIntervalId);
  return Promise.resolve(true);
}
