import { commonLegacyApiRequest } from '@core/service/commonService';
import { ApiCommand } from '@/store/modules/api';
import { usePrint } from '@/hooks/usePrint';
import { useProtectedDefaultCompany } from '@/hooks/useProtectedDefaultCompany';
import { useCustomLocales } from '../../useCustomLocales';

export type FsspHistoryItem = any & {
  id: number;
  action: string;
  // more
}

let applications: { value: string; label: string }[];

export function useFetchFsspRequestsHistory() {
  const { fetchEnforcementsApplications } = usePrint();
  const { translateResolutionValue } = useCustomLocales();

  const fetchWritoeFsspHistory = async (id: string) => {
    await fetchTranslations();
    const { response } = await commonLegacyApiRequest({
      command: ApiCommand.fetchWritoeFfspHistory,
      params: {
        writ_of_execution: id,
        limit: 10000,
      },
    });

    return (response.results ?? [])
      .sort((a: FsspHistoryItem, b: FsspHistoryItem) => a.id - b.id)
      .map((item: FsspHistoryItem) => {
        item.action = translateApplication(item.action);
        return item;
      });
  };

  function translateApplication(action: string) {
    const application = applications?.find(
      ((record) => record.value === action),
    )?.label;
    const resolution = translateResolutionValue(action);

    return application ?? resolution ?? action;
  }

  async function fetchTranslations() {
    if (applications) {
      return;
    }
    try {
      ({ response: { applications } } = await fetchEnforcementsApplications());
    } catch (e) {
      console.warn('Failed to fetch translations', e);
    }
  }

  return {
    fetchWritoeFsspHistory,
    translateApplication,
    fetchTranslations,
  };
}
