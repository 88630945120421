import { DateTimeString } from '@/service/api/reporting/tile';
import { DateTime } from 'luxon';

export const formatDateTime = (date: Date, locale = 'ru-RU') => formatDate(
  date, locale, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    minute: '2-digit',
    hour: '2-digit',
    second: '2-digit',
  },
);

export const formatDate = (date?: Date|string, locale?: string, options: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
}): string | undefined => (
  date ? (
    new Intl.DateTimeFormat(locale, options)
  ).format(date instanceof Date ? date : new Date(date)) : date
);

const weekdayIntl = new Intl.DateTimeFormat('ru-RU', {
  weekday: 'short',
});

export const formatWeekday = (date: Date) => weekdayIntl.format(date);

const dayIntl = new Intl.DateTimeFormat('ru-RU', {
  day: 'numeric',
});

export const formatDay = (date: Date) => dayIntl.format(date);

const monthAndYearIntl = new Intl.DateTimeFormat('ru-RU', {
  year: 'numeric',
  month: 'long',
});

export const formatMonthAndYear = (
  date: Date, locale = 'ru-RU',
) => (new Intl.DateTimeFormat(locale, {
  year: 'numeric',
  month: 'long',
})).format(date);

export const formatMonthAndYearShort = (
  date: Date, locale = 'ru-RU',
) => (new Intl.DateTimeFormat(locale, {
  year: 'numeric',
  month: 'numeric',
})).format(date);

const yearIntl = new Intl.DateTimeFormat('ru-RU', {
  year: 'numeric',
});

export const formatYear = (date: Date) => yearIntl.format(date);

const monthIntl = new Intl.DateTimeFormat('ru-RU', {
  month: 'long',
});

export const formatMonth = (date: Date) => monthIntl.format(date);

export const toSafeDate = (dateRaw: string|Date|null) => {
  if (!dateRaw) {
    return null;
  }
  if (dateRaw instanceof Date) {
    return dateRaw;
  }
  return parseIfDate(dateRaw);
};

// format is DD.MM.YYY
export const parseDateRU = (dateString: string): Date | undefined => {
  if (!dateString) {
    return undefined;
  }
  try {
    const parts = dateString.split('.');
    return new Date(parseN(parts[2]), parseN(parts[1]) - 1, parseN(parts[0]));
  } catch (e) {
    console.error(`Invalid date format: ${dateString}`);
    return undefined;
  }
};

function parseN(n: string) {
  return parseInt(n, 10);
}

export const parseIfDate = (date: string): Date|null => {
  if (typeof date !== 'string') return null;
  const [year, month, day] = date.split('-');
  if (!year || !month || !day) return null;
  const d = new Date(date);
  if (d.toString().toLowerCase() === 'invalid date') return null;
  return d;
};

export const dateToApiDate = (date: Date): DateTimeString | null => {
  if (!date) {
    return null;
  }
  const year = date.getFullYear();
  const month = `${date.getMonth() + 1}`.padStart(2, '0');
  const day = `${date.getDate()}`.padStart(2, '0');

  return [year, month, day].join('-');
};

export const MONTH_TRANSLATIONS_FULL = Object.fromEntries(
  new Array(12).fill(null).map((_, i) => [
    i + 1,
    DateTime.fromJSDate(new Date(2021, (i))).toFormat('LLL', {
      locale: 'ru',
    }),
  ]),
);

export const formatValue = (value: any): string => {
  if (value instanceof Date) {
    const date = new Date(value);
    if (!Number.isNaN(date.getTime())) {
      return new Intl.DateTimeFormat('ru-RU').format(date);
    }
  }
  return value; // Если не дата, вернуть как есть
};
