import { useStore } from 'vuex';
import { ApiResponse } from '@/service/api';
import {
  computed, onMounted, ref, watch,
} from 'vue';
import { ListingRequestSource } from '@/store/modules/api';
import { useDefaultCompany } from './useDefaultCompany';

export type AppMessages = {
  id: number;
  content: string;
  company: string;
  report : string;
  url: string;
  type: string;
  failed_documents_archive: string;
  created_at: string;
  is_read: boolean;
}

export type FetchMessagesModel = ListingRequestSource<AppMessages> & { company: number; o: string };

export const useMessages = () => {
  const store = useStore();
  const { companyId } = useDefaultCompany();
  const messages = ref<AppMessages[]>([]);
  const totalMessages = ref<number>(0);
  const limit = ref<number>(100);
  const page = ref<number>(1);

  const params = computed(() => ({
    limit: limit.value,
    page: page.value,
    filters: {
      company: companyId.value,
    },
  }));

  const fetchMessages = async () => {
    const { status, response } = await store.dispatch('messages/fetchPrintReport', params.value);

    if (status) {
      messages.value = response.results;
      totalMessages.value = response.count;
    }
  };

  onMounted(async () => {
    await fetchMessages();
  });

  watch(params, async (value) => {
    await fetchMessages();
  });

  const markAsReadMessage = (id: number): Promise<ApiResponse<any>> => store.dispatch(
    'messages/markAsReadMessage', id,
  );

  const getReportById = (id: number): Promise<ApiResponse<any>> => store.dispatch(
    'messages/fetchPrintReportId', id,
  );

  const deleteReportMessages = (id: number): Promise<ApiResponse<any>> => store.dispatch(
    'messages/deleteReportMessages', id,
  );

  return {
    totalMessages,
    limit,
    page,
    messages,
    hasUnreadMessages: computed(() => store.getters['messages/hasUnreadMessages'] as boolean),
    markAsReadMessage,
    markAllAsReadMessage: async () => {
      await Promise.all(
        messages.value.map(({ id }) => markAsReadMessage(id)),
      );
    },
    getReportById,
    deleteReportMessages,
  };
};
