import { IDialogComponent, PrintPayload } from '@/hooks/useDialog';
import {
  computed, ref, SetupContext, toRefs, watch, watchEffect,
} from 'vue';
import { useAsyncState } from '@vueuse/core';
import { useLocalI18n } from '@/hooks/useLocalI18n';
import { Company, useCompanies } from '@/hooks/useCompanies';
import {
  GenerateMergedAttachmentsModel,
  mapDocumentAttachmentToPrintDocument,
  PrintDocument,
  PrintType,
  UploadEnforcementsApplicationsModel,
  usePrint,
} from '@/hooks/usePrint';
import { useProtectedDefaultCompany } from '@/hooks/useProtectedDefaultCompany';
import { useCompanyAttachments } from '@/hooks/useCompanyAttachments';
import { DictType, useDicts } from '@/hooks/useDicts';
import { useDefaultCompany } from '@/hooks/useDefaultCompany';
import { SourceErrors, useErrors } from '@/hooks/useErrors';
import { IToastLevel, useToast } from '@/hooks/useToast';
import { SignalType, useSignal } from '@/hooks/useSignal';
import { RouteLocationRaw, useRouter } from 'vue-router';
import { useTaskProgress } from '@/hooks/useTaskProgress';
import { dateToApiDate } from '@/utils/date';
import { useCompanySettings } from '@/components/automation/useCompanySettings';
import { commonLegacyApiRequest } from '@core/service/commonService';
import { ApiCommand } from '@/store/modules/api';
import {
  PrintModel,
  ValidateCompanyResponse,
} from '@/components/dialog/dialogs/print/printDialogTypes';
import {
  getCompanyFirstValidationRoute,
  getCompanyValidationToasts,
} from '@/components/dialog/dialogs/print/printDialogUtils';
import { ProductionType } from '@/hooks/useConstructor';
import { useCourtTestSetting } from '@/components/dialog/dialogs/print/useTestCourtSetting';
import { useCustomLocales } from '@/components/dialog/dialogs/debtor/useCustomLocales';
import { useLocalCertificate } from '@/components/dialog/dialogs/print/localSign/useLocalCertificate';
import { awaitFrame } from '@/utils/window';
import { IntegrationDialog } from '@/pages/exchange/integration/dialogs/integrationDialogs';

type Props = {
  productionType: PrintPayload['productionType'];
  debtorIds: PrintPayload['debtorIds'];
  someoneIsOrganization: PrintPayload['someoneIsOrganization'];
}

export const usePrintDialog = (props: Props, emit: SetupContext['emit'] | any) => {
  const { t } = useLocalI18n('print');
  const { productionType, someoneIsOrganization } = toRefs(props);
  const companyBaseType = ref<string|null>(null);

  const isExecutive = productionType.value === ProductionType.executive;

  const isEmail = ref(false);

  const typePeniCalculation = ref<Company['type_peni_calculation']>();
  const showAutomaticAttachments = ref<Company['show_automatic_attachments']>();

  const model = ref<PrintModel>({
    wholePeriod: true,
    from: null,
    to: null,
    moratorium: true,
    courtTest: true,
    scanMode: false,
    duplexPrinting: false,
    penalty_key_rate_mode: 'k_rate',
    sendPochta: false,
    sendEmail: false,
    isOrdered: true,
    useSignatureAndStamp: true,
    returnDuplicatePayments: false,
    appealFns: false,
    offlineCourt: false,
    sendDraftCourtOrder: false,
    subType: '',
    subTypeOptions: [],
    extra: [],
    type: isExecutive ? 'fns_request' : 'court_order',
    complaint: null,
    court_complaint_overdue: null,
    sendComplaint: false,
    use_company_signature_and_stamp: false,
    subTypeSelected: '',
  });

  useCourtTestSetting(model);

  const isEnforcementsSelected = ref<boolean>(false);

  const { companyId, companyModuleType } = useProtectedDefaultCompany();

  const {
    fetchDocuments,
    formatedDocuments,
    formateDocuments,
    documents: documentsModel,
    isUploading: documentListIsUpdating,
    updateDocuments,
    fetchCompanyAttachments,
    isLoading,
  } = useCompanyAttachments(
    companyId,
    productionType,
    computed(() => ((productionType.value === ProductionType.judicial && showAutomaticAttachments.value) ? model.value.type : 'print') as string),
    computed(() => ((productionType.value === ProductionType.judicial && showAutomaticAttachments.value) ? model.value.subType : '') as string),
    computed(() => model.value.sendPochta && model.value.isOrdered),
  );

  watch(computed(() => model.value.type), async (t) => {
    if (t) {
      await fetchDocuments();
      formateDocuments();
    }
  });
  watch(computed(() => model.value.subType), async (st) => {
    if (st) {
      await fetchDocuments();
      formateDocuments();
    }
  });

  const {
    fetchCompany,
  } = useCompanies();

  fetchCompany(companyId.value).then((response) => {
    if (response.status) {
      model.value.penalty_key_rate_mode = response.response.penalty_key_rate_mode;
      typePeniCalculation.value = response.response.type_peni_calculation;
      companyBaseType.value = response.response.base_type;
    }
  });

  watch(computed(() => model.value.wholePeriod), () => {
    model.value.from = null;
    model.value.to = null;
  });

  watch(computed(() => model.value.type), () => {
    const type = model.value.type ? state.value.find((e) => e.value === model.value.type) : null;
    isEnforcementsSelected.value = (type && type.complaint_text) || false;
  });

  const { getDict } = useDicts();
  const penaltyKeyRateModeOptions = computed(() => {
    const options = getDict(DictType.penaltyKeyRates);
    if (typePeniCalculation.value === '395') {
      return options.value;
    }
    return options.value.filter(({ value }: any) => value !== 'p_pen');
  });

  const {
    generateMergedAttachments,
    fetchEnforcementsApplications,
    uploadEnforcementsApplications,
  } = usePrint();

  const { company } = useDefaultCompany();

  const {
    errorsMap,
    setErrors,
    clearErrors,
  } = useErrors<keyof GenerateMergedAttachmentsModel>();

  const { showToast, showDangerToast, showPureDangerToast } = useToast();

  const {
    awaitSignalResponse,
  } = useSignal();

  const draggableContainerLoadingClass = computed(() => (
    documentListIsUpdating.value ? 'documentsList-disabled' : false
  ));

  const router = useRouter();

  const { startTaskProgress } = useTaskProgress();
  const { getLocalCertificate } = useLocalCertificate();

  const withoutFee = computed(() => companySettings.value?.without_fee);
  const useSignatureAndStamp = computed(() => companySettings.value?.use_company_signature_and_stamp);

  const { companySettings, updateCompanySettings } = useCompanySettings();

  watch(companySettings, (settings) => {
    if (settings) {
      model.value.moratorium = settings.moratorium_enabled;
      showAutomaticAttachments.value = settings.show_automatic_attachments;
    }
  }, { immediate: true });

  const onMoratoriumUpdated = (moratorium_enabled: boolean) => {
    model.value.moratorium = moratorium_enabled;
    updateCompanySettings({
      moratorium_enabled,
    });
  };

  const onToggleWithoutFee = (
    without_fee: boolean,
  ) => updateCompanySettings({ without_fee });

  const onToggleUseSignature = (
    use_company_signature_and_stamp: boolean,
  ) => updateCompanySettings({ use_company_signature_and_stamp });

  const onChangeSendPochta = (
    value: boolean,
  ) => {
    if (value) {
      model.value.courtTest = false;
      model.value.isOrdered = true;
    }
  };

  const onChangeSendEmail = (
    value: boolean,
  ) => {
    isEmail.value = value;
    model.value.sendEmail = value;
  };

  const isOrderedOptions = [
    {
      label: t('period.isOrdered.false'),
      value: false,
    }, {
      label: t('period.isOrdered.true'),
      value: true,
    },
  ];

  // id регионов, для которых скрываем офлайн подачу
  const regionsToHideOfflineCourtCheckbox = [77, 83, 81];
  const offlineCourtIsHidden = computed(
    () => regionsToHideOfflineCourtCheckbox.includes(companySettings.value?.default_region as number),
  );

  const courtOrderSubTypeSelectOptions = ref<{value: string; label: string}[]>([]);

  const {
    locales, fetchLocales,
  } = useCustomLocales();

  // Загружаем документы и запросы
  const { state } = useAsyncState<{
    value: string;
    label: string;
    complaint_text?: boolean;
    extra?: {[key: string]: {type: string; label?: string; choices?: []};
    } | any;
  }[]>(async () => {
    let options;
    await fetchLocales();
    if (isExecutive) {
      const data = locales.value.ru['debtor.courts.executive_type'];
      options = Object.keys(data).map((key) => ({
        label: data[key],
        value: key,
      }));
      Object.keys(locales.value.ru['debtor.courts.complaint_type']).forEach((key) => {
        if (key === 'petition_debtor_identifier') {
          options.push({ label: locales.value.ru['debtor.courts.complaint_type'][key], value: key });
        }
      });
      const { status, response } = await fetchEnforcementsApplications();
      if (!status) {
        await showDangerToast({
          label: 'print.toast.noEnforcementsApplications',
          message: '',
        });
        documentListIsUpdating.value = false;
        return options;
      }
      options.push(...response.applications);

    } else {
      const judicialPrintTypes = Object.entries(locales.value.ru['debtor.courts.judicial_type'])
        .sort(([key1, str1], [key2, str2]) => {
          if (key1 === 'court_order') {
            return -1;
          }
          if (key2 === 'court_order') {
            return 1;
          }
          return str1.localeCompare(str2);
        }).map(([value, label]) => ({ label, value })) || [];

      const courtOrderPrintTypes = Object.entries(locales.value.ru['debtor.courts.court_order_type'])
        .map(([value, label]) => ({ label, value })) || [];

      options = judicialPrintTypes;
      courtOrderSubTypeSelectOptions.value = courtOrderPrintTypes;
    }
    return options;
  }, []);

  const typeSelectOptions = computed(() => state?.value);

  const resetModelOptions = () => {
    model.value.subTypeOptions = [];
    model.value.subType = '';
    model.value.subTypeSelected = '';
    model.value.extra = [];
  };

  watch(computed(() => model.value.type), () => {
    if (productionType.value === ProductionType.executive) {
      if (model.value.type) {
        const item = typeSelectOptions.value.find((i) => i.value === model.value.type);
        if (item && item.extra) {
          if ('resolution_type' in item.extra || 'petition_type' in item.extra) {
            checkSubTypeOptions(item.extra);
          }

          const extras: { key: string; label?: string; type: string; value: any }[] = [];
          Object.keys(item.extra).forEach((key) => {
            const type = item.extra[key].type;
            if (['boolean', 'date', 'string'].indexOf(type) >= 0) {
              extras.push({
                key, label: item.extra[key].label, type, value: null,
              });
            }
          });
          model.value.extra = extras;
        } else {
          resetModelOptions();
        }
      } else {
        resetModelOptions();
      }
    } else if (productionType.value !== ProductionType.judicial) {
      resetModelOptions();
    }
  });

  watchEffect(async () => {
    if (model.value.type === 'court_order' && courtOrderSubTypeSelectOptions.value.length && showAutomaticAttachments.value && productionType.value === ProductionType.judicial) {
      await awaitFrame();
      model.value.subTypeOptions = courtOrderSubTypeSelectOptions.value;
      model.value.subType = 'court_order';
      model.value.subTypeSelected = '';
      model.value.extra = [];
    } else if (productionType.value !== ProductionType.executive) {
      resetModelOptions();
    }
  });

  // @ts-ignore
  const checkSubTypeOptions = (extra: { resolution_type?: { choices: string[] }; petition_type?: { choices: string[] } }) => {
    if (extra?.resolution_type && extra?.resolution_type?.choices.length) {
      const options = extra?.resolution_type?.choices.map((item: string) => ({
        label: item,
        value: item,
      }));
      model.value.subTypeOptions = options;
      model.value.subType = options[0].value;
      model.value.subTypeSelected = 'resolution_type';
      return;
    }

    if (extra?.petition_type && extra?.petition_type?.choices.length) {
      const options = extra?.petition_type?.choices.map((item: string) => ({
        label: item,
        value: item,
      }));
      model.value.subTypeOptions = options;
      model.value.subType = options[0].value;
      model.value.subTypeSelected = 'petition_type';
      return;
    }

    model.value.subTypeOptions = [];
    model.value.subType = '';
    model.value.subTypeSelected = '';
  };

  /**
   * @param encrypt использовать ЭЦП
   * @param execComplaint ИП отправка запроса
   */
  const submit = async (encrypt: boolean, execComplaint: boolean) => {
    if (documentListIsUpdating.value) {
      return;
    }
    clearErrors();
    console.log('encrypt', encrypt);

    const selectItem = typeSelectOptions.value.find((e) => e.value === model.value.type);

    if (isExecutive && isEnforcementsSelected.value && execComplaint) {
      const payload: UploadEnforcementsApplicationsModel = {
        company_id: company.value!.id,
        debtor_ids: props.debtorIds || [],
        action: model.value.type || '',
        printed: documentsModel.value.map(mapDocumentAttachmentToPrintDocument).filter((doc) => Boolean(doc.is_active)),
        // ...(signLocal ? { encrypt_provider: 2, attorney_by_user: true } : {}),
      };
      if (model.value.subTypeSelected === 'resolution_type') payload.resolution_type = model.value.subType;
      if (model.value.subTypeSelected === 'petition_type') payload.petition_type = model.value.subType;

      model.value.extra.forEach((e) => {
        switch (e.type) {
          case 'string':
            payload[e.key] = e.value;
            break;
          case 'boolean':
            payload[e.key] = !!e.value;
            break;
          case 'date':
            payload[e.key] = dateToApiDate(e.value);
            break;
          default:
        }
      });

      const { status, response } = await uploadEnforcementsApplications(payload);

      if (!status) {
        await showDangerToast({
          label: 'print.toast.noEnforcementsApplications',
          message: '',
        });

        Object.keys(response).forEach((key) => {
          const field = model.value.extra?.find((e) => e.key === key) || null;
          if (field) {
            showDangerToast({
              duration: 8000,
              label: field.label,
              message: response[key].join(' '),
            });
          }
        });
        return;
      }
      emit('close');

      await startTaskProgress({
        id: response.uuid,
        key: response.uuid,
        uuid: response.uuid,
        action: 'startPrintTask',
        encrypt,
        progressbars: [],
        companyId: companyId.value,
        label: 'printTask',
      });
      return;
    } // end if isExecutive && isEnforcementsSelected.value

    const printTypesWithoutFee = [
      'complaint_art_6_1',
      'complaint_art_331',
      'petition',
      'petition_return_court',
      'compaint_statetax_refund',
      'petition_without_case_number',
      'petition_court_decision_copy',
      'petition_debtor_identifier',
    ] as PrintType[];

    if (encrypt && productionType.value === ProductionType.judicial) { // https://jira.urrobot.tech/browse/URROBOT-6837
      if (!model.value.sendPochta // https://jira.urrobot.tech/browse/URROBOT-5584
      && !printTypesWithoutFee.includes(model.value.type)
      && !withoutFee.value
      && !documentsModel.value.find((d) => d.type === 'state_duty_payment')?.is_active
      ) {
        await showDangerToast({
          label: 'Выберите госпошлину в приложении печати, или нажмите на кнопку "Заявитель освобожден от уплаты"',
          level: IToastLevel.danger,
        });
        return;
      }

      if (!companyBaseType.value && someoneIsOrganization.value) {
        await showDangerToast({
          label: 'Заполните поле: Основание для взыскания задолженности',
          level: IToastLevel.danger,
        });
        await router.push(`/organizations#${JSON.stringify({
          component: IDialogComponent.company,
          addInRoute: true,
          preventCloseOnRouteChange: true,
          payload: {
            id: company.value?.id,
            isInitiallyEditing: true,
            tab: 'common',
          },
        })}` as RouteLocationRaw);
        return;
      }
    }

    const filters = await awaitSignalResponse<Record<any, any>>(
      SignalType.getDebtorFilters,
      SignalType.debtorFilters,
    );

    const { certificate: localCertificate, terminalError } = await getLocalCertificate(encrypt);
    if (terminalError) {
      return;
    }

    documentListIsUpdating.value = true;

    if (encrypt || model.value.sendPochta) {
      const validateResponse = await commonLegacyApiRequest<ValidateCompanyResponse>({
        command: ApiCommand.constructorValidateCompany,
        params: { company_id: companyId.value },
        data: {
          check_company_signature_and_stamp: encrypt && useSignatureAndStamp.value,
          check_gosuslugi: !model.value.sendPochta,
          check_pochta_ru: model.value.sendPochta,
        },
      });

      if (!validateResponse.status) {
        console.log('validateResponse.status failed');
        if (!validateResponse.response) {
          console.log('validateResponse.response failed');
          await showDangerToast({
            label: 'Проверка данных завершена с ошибкой, попробуйте повторить',
          });
          return;
        }
        const ignoreFields: Array<keyof ValidateCompanyResponse> = [];
        if (!model.value.sendPochta) {
          ignoreFields.push('pochta_ru');
        }
        const validationToasts = getCompanyValidationToasts(
          validateResponse.response as ValidateCompanyResponse,
          ignoreFields,
          companyId.value,
          router,
        );
        const firstErrorRoute = getCompanyFirstValidationRoute(
          validateResponse.response as ValidateCompanyResponse,
          ignoreFields,
          companyId.value,
        );
        console.log('validationToasts', validationToasts, ignoreFields);
        if (validationToasts.length) {
          emit('close');
          validationToasts.forEach(showToast);
          if (firstErrorRoute) {
            await router.push(firstErrorRoute as RouteLocationRaw);
          }
          return;
        }
      }
    }

    let extraCourtAttachments: Array<PrintDocument> = [];

    if (model.value.type === 'court_order' && showAutomaticAttachments.value) {
      const { response } = await fetchCompanyAttachments({
        filters: {
          production_type: productionType.value,
          company_id: companyId.value,
          for_registered_letters: model.value.sendPochta && model.value.isOrdered,
          for_automatic: true,
        },
        page: 1,
        limit: 1000,
      });

      extraCourtAttachments = response.results
        .filter(({ is_active, group_name, subgroup_name }) => Boolean(is_active)
        && group_name === 'court_order' && subgroup_name !== model.value.subType && Boolean(subgroup_name))
        .map(mapDocumentAttachmentToPrintDocument);
    }

    const docsAttachments = documentsModel.value.map(mapDocumentAttachmentToPrintDocument).filter((doc) => Boolean(doc.is_active));

    const { status, response } = await generateMergedAttachments({
      production_type: props.productionType,
      company_id: company.value!.id,
      pochta_ru: model.value.sendPochta,
      is_ordered: model.value.isOrdered,
      appeal_fns: model.value.appealFns,
      email: model.value.sendEmail,
      filters: {
        ...filters,
        module: companyModuleType.value,
        debtor_ids: props.debtorIds || [],
      },
      config: {
        ...(model.value.wholePeriod ? {} : {
          date_from: dateToApiDate(model.value.from!),
          date_to: dateToApiDate(model.value.to!),
        }),
        encrypt,
        court_test: model.value.courtTest,
        moratorium_enabled: model.value.moratorium,
        scan_mode: model.value.scanMode,
        duplex_printing: model.value.duplexPrinting,
        penalty_key_rate_mode: model.value.penalty_key_rate_mode,
        group_name: model.value.type,
        // @ts-ignore
        printed: [
          ...(model.value.sendPochta && model.value.isOrdered
            ? docsAttachments.filter((doc) => Boolean(doc.for_registered_letters))
            : docsAttachments),
          ...extraCourtAttachments,
          ...(selectItem?.complaint_text ? [model.value.subTypeSelected ? {
            key: model.value.subType,
            name: model.value.subType,
            order: -1,
            is_active: true,
          } : {
            key: model.value.type,
            name: selectItem?.label || model.value.subType,
            order: -1,
            is_active: true,
          }] : []),
        ],
        ...(localCertificate ? { encrypt_provider: 2, attorney_by_user: true } : {}),
        ...(productionType.value === 'judicial' || (isExecutive && !selectItem?.complaint_text) ? { type: model.value.type } : {}),
        return_duplicate_payments: model.value.returnDuplicatePayments,
      },
      ...(productionType.value === 'judicial' && model.value.offlineCourt
        ? { offline_court: model.value.offlineCourt }
        : {}
      ),
      ...(productionType.value === 'judicial' && model.value.sendDraftCourtOrder
        ? { send_draft_court_order: model.value.sendDraftCourtOrder }
        : {}
      ),
    });

    if (!status) {
      if ((response as any).__statusCode === 402) {
        await showPureDangerToast({
          label: `${(response as any).detail} (нажмите, чтобы перейти)`,
          duration: null,
          isCloseable: true,
          onClick: () => router.push('/panel/tariffs' as RouteLocationRaw),
        });
        // переход при отсутствии лицензии
        await router.push('/panel/tariffs' as RouteLocationRaw);
      } else {
        setErrors(Object.entries(response) as unknown as SourceErrors<keyof GenerateMergedAttachmentsModel>);
      }
      documentListIsUpdating.value = false;
      return;
    }

    emit('hide');
    await startTaskProgress({
      id: response.id,
      key: response.id,
      uuid: response.id,
      action: 'startPrintTask',
      encrypt,
      progressbars: [],
      companyId: companyId.value,
      label: 'printTask',
      ...(localCertificate ? { cert_thumbprint: localCertificate.thumbprint } : {}),
    });
  };

  return {
    t,
    isLoading,
    isEmail,
    isEnforcementsSelected,
    model,
    submit,
    draggableContainerLoadingClass,
    errorsMap,
    onToggleWithoutFee,
    onToggleUseSignature,
    withoutFee,
    useSignatureAndStamp,
    updateDocuments,
    documentListIsUpdating,
    penaltyKeyRateModeOptions,
    onChangeSendPochta,
    onChangeSendEmail,
    isOrderedOptions,
    documentsModel,
    onMoratoriumUpdated,
    offlineCourtIsHidden,
    typeSelectOptions,
    formatedDocuments,
  };
};
