import { Module } from 'vuex';
import { StoreState } from '@/store';
import {
  FetchCompanyBalanceModel, FetchCompanyLicenses,
} from '@/hooks/useFinance';
import {
  ApiCommand, ApiRequest,
} from '@/store/modules/api';
import { dateToApiDate } from '@/utils/date';

export type FinanceState = {
}

type FinanceModule = Module<FinanceState, StoreState>;

const debtorDataModelToApiModel = (model: any) => {
  return {
    end_balance: model.value,
    start_balance: model.prev_value,
    title: model.title,
    end_date: dateToApiDate(model.end_date),
    start_date: dateToApiDate(model.start_date),
  };

};

const debtorPenaltiesDataModelToApiModel = (model: any) => {
  return { value: model.value, start_date: dateToApiDate(model.start_date) };
};

export const namespaced = true;

export const actions: FinanceModule['actions'] = {
  async fetchCompanyBalance({ dispatch }, { id }: FetchCompanyBalanceModel) {
    const { status, response } = await dispatch('api/request', {
      command: ApiCommand.fetchCompanyBalance,
      params: { id },
    } as ApiRequest, { root: true });
    return { status, response };
  },
  async fetchAvailableTariffs({ dispatch }) {
    const { status, response } = await dispatch('api/request', {
      command: ApiCommand.fetchAvailableLicenses,
    } as ApiRequest, { root: true });
    return { status, response };
  },
  async fetchCompanyLicenses({ dispatch }, { id } : FetchCompanyLicenses) {
    const { status, response } = await dispatch('api/request', {
      command: ApiCommand.fetchCompanyLicenses,
      params: { id },
    } as ApiRequest, { root: true });
    return { status, response };
  },
  async fetchCompanyLicensesActiveCount({ dispatch }, { id } : FetchCompanyLicenses) {
    const { status, response } = await dispatch('api/request', {
      command: ApiCommand.fetchCompanyLicensesActiveCount,
      params: { id },
    } as ApiRequest, { root: true });
    return { status, response };
  },
  async companyLicenseBuy({ dispatch }, { id, licenseId }: { id: number; licenseId: number }) {
    const { status, response } = await dispatch('api/request', {
      command: ApiCommand.companyLicenseBuy,
      params: { id },
      data: { id: licenseId },
    } as ApiRequest, { root: true });
    return { status, response };
  },
  async updateDebtorDebtData({ dispatch }, payload: any) {
    const { status, response } = await dispatch('api/request', {
      command: ApiCommand.updateDebtorDebtData,
      params: {
        id: payload.id,
      },
      data: debtorDataModelToApiModel(payload.model),
    } as ApiRequest, { root: true });
    return { status, response };
  },
  async updateDebtorPenaltiesData({ dispatch }, payload: any) {
    const { status, response } = await dispatch('api/request', {
      command: ApiCommand.updateDebtorPenaltiesData,
      params: {
        id: payload.id,
      },
      data: debtorPenaltiesDataModelToApiModel(payload.model),
    } as ApiRequest, { root: true });
    return { status, response };
  },
  async updateDebtorDataConvictingPeriod({ dispatch }, payload: any) {
    const { status, response } = await dispatch('api/request', {
      command: ApiCommand.updateDebtorDataConvictingPeriod,
      params: {
        id: payload.id,
      },
      data: payload.model,
    } as ApiRequest, { root: true });
    return { status, response };
  },
};
