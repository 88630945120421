import {
  computed, ref, Ref, watch,
} from 'vue';
import { Debtor } from '@/hooks/useDebtors';
import { useLocalI18n } from '@/hooks/useLocalI18n';
import { ActionType, useActiveTable } from '@/components/activeTable/useActiveTable';
import { ProductionType } from '@/hooks/useConstructor';
import { ActiveFormField, ActiveFormFieldType } from '@/hooks/useActiveForm';
import { useDefaultCompany } from '@/hooks/useDefaultCompany';
import { getDebtorFinancesTableColumns } from './tableColumns';
import { mapFinanceData } from './tableData';
import {
  useDebtorFinanceApi,
} from '@/components/dialog/dialogs/debtor/tabs/finance/useDebtorFinanceApi';
import { CompanyModuleType } from '@/pages/debtors/_module/useDebtorsActions';
import {
  usePaidUpsEditModal,
} from '@/components/dialog/dialogs/debtor/tabs/finance/useTable/usePaidUpsEditModal';
import { useInjectDebtorDialog } from '@/components/dialog/dialogs/debtor/useInjectDebtorDialog';
import { SignalType, useSignal } from '@/hooks/useSignal';
import { commonLegacyApiRequest } from '@urrobot/core/service/commonService';
import { ApiCommand, OrderDirection } from '@/store/modules/api';
import {
  ActiveTabKey,
  DebtorFinanceData,
  Debts, Fee,
  FinanceTabKey,
  FullFinanceData,
  isIncomeSourceTab, Penalties,
  Recalculations,
} from '../types';
import { useDocumentsApi } from '@/hooks/useDocumentsApi';
import { isAfter } from '@/utils/dateFns';
import { useErrors } from '@/hooks/useErrors';
import { useStore } from 'vuex';
import { useDefaultCompanyPermissions } from '@/hooks/useDefaultCompanyPermissions';

type Agreement = {
  id: string;
  number: string;
  valid_from: string;
  valid_until: string;
}

export const useTable = (
  debtor: Ref<Debtor>,
  productionType: Ref<ProductionType>,
  activeTab: Ref<ActiveTabKey>,
) => {
  const { debtorId } = useInjectDebtorDialog();
  const { companyId, companyModuleType } = useDefaultCompany();
  const { fetchDebtorAgreements } = useDocumentsApi();
  const { t } = useLocalI18n(`debtor.finance.column.module_type_${companyModuleType.value}`);
  const debtorFinanceDataApi = useDebtorFinanceApi();
  const { dispatchSignal } = useSignal();
  const fullData = ref<FullFinanceData>({} as FullFinanceData);
  const isLoading = ref<boolean>(false);
  const agreementId = ref<string | null>(null);
  const agreements = ref<Agreement[]>([]);
  const financeDialogs = usePaidUpsEditModal(computed(() => debtor.value.pk as number));
  const financeDataModel = ref<DebtorFinanceData>();
  const editingItem = ref(null);
  let agreementChange = false;
  const { hasWritePermission } = useDefaultCompanyPermissions(productionType);

  const store = useStore();

  const { errorsMap, clearErrors, setErrors } = useErrors<any>();

  const {
    records,
    columns,
    actions,
    fetchData: refetch, sort,
  } = useActiveTable<any & {id: number}, any, 'id'>({

    keyField: 'id',
    name: 'debtor-finance-data',

    async fetch({ params, signal }) {
      if (!debtor.value || isIncomeSourceTab(activeTab.value)) {
        return {
          count: 0,
          results: [],
        };
      }
      isLoading.value = true;

      if (activeTab.value === 'paid-ups'
        && companyModuleType.value === CompanyModuleType.Communal
        && productionType.value === ProductionType.executive) {

        const { status, response } = await debtorFinanceDataApi.fetchList({
          ...params,
          filters: {
            debtor_data: debtor.value.pk,
            type: 'paid_up',
          },
        });
        if (!status) {
          isLoading.value = false;
          throw new Error('Fetch debtorFinanceDataApi.fetchList data error');
        }
        isLoading.value = false;
        return {
          ...response,
          results: response.results.map((record) => ({
            ...record,
            Оплата: record.value,
          })),
        };
      }

      if (!agreementChange) {
        const { status, response: docs } = await fetchDebtorAgreements({
          filters: {
            debtor_id: debtorId.value,
            company_id: companyId.value!,
          },
          signal,
        });
        agreements.value = docs.results;

        if (docs.results.length) {
          agreementId.value = docs.results[0].id;
        }
      }

      const agreement = agreements.value.find((v) => v.id === agreementId.value);

      const response = await commonLegacyApiRequest<DebtorFinanceData>({
        command: ApiCommand.debtorDataGetFinances,
        params: {
          debtor_id: debtorId.value,
          module: companyModuleType.value,
          production_type: productionType.value,
          company_id: companyId.value,
          date_from: agreement?.valid_from,
          date_to: agreement?.valid_until,
        },
      });

      if (!response.status) {
        isLoading.value = false;
        throw new Error('Fetch finance data error');
      }
      financeDataModel.value = response.response;
      isLoading.value = false;

      let data = mapFinanceData({
        fullData,
        debtor: debtor.value,
        financeData: financeDataModel.value,
        companyModuleType: companyModuleType.value,
        activeTab: params.filters!.__tab as FinanceTabKey,
      });

      data = data.map((d: any) => ({ ...d, prev_value: d.value }));

      isLoading.value = false;

      type HasDates = {
        date: Date;
        transaction_date: Date;
        start_date: Date;
      }

      if (params.ordering?.length) {
        const order = params.ordering[0];

        (['date', 'transaction_date', 'start_date'] as (keyof HasDates)[]).forEach((key) => {
          if (order.key === key) {
            data.sort((a: HasDates, b: HasDates) => (
              (order.direction === OrderDirection.asc ? 1 : -1) * (isAfter(a[key], b[key]) ? 1 : -1)
            ));
          }
        });
      }

      return {
        count: data.length,
        results: data,
      };
    },

    filters: computed(() => (([
      {
        key: '__tab',
        field: '__tab',
        type: ActiveFormFieldType.input,
        defaultValue: activeTab.value,
      },
      {
        key: 'debtor_id',
        field: 'debtor_id',
        type: ActiveFormFieldType.input,
        defaultValue: debtor.value?.debtor.pk,
      },
    ] as Array<ActiveFormField<any> | boolean>)
      .filter(Boolean) as Array<ActiveFormField<any>>)),

    columns: computed(() => (
      !financeDataModel.value || isIncomeSourceTab(activeTab.value) ? [] : getDebtorFinancesTableColumns({
        financeData: financeDataModel.value,
        companyModuleType: companyModuleType.value,
        activeTab: <FinanceTabKey>activeTab.value,
      }).map((column) => ({
        ...column,
        field: column.key,
        label: column.label || t(`${activeTab.value}.${String(column.key).split('.').pop()}`),
      })))),

    defaultSort: computed(() => {
      if (activeTab.value === Recalculations) {
        return [{
          key: 'date',
          direction: OrderDirection.asc,
        }];
      }
      if ([Debts, Penalties, Fee].includes(activeTab.value)) {
        return [{
          key: 'start_date',
          direction: OrderDirection.asc,
        }];
      }
      if (companyModuleType.value === 2) {
        return [{
          key: 'transaction_date',
          direction: OrderDirection.asc,
        }];
      }
      return [{
        key: 'date',
        direction: OrderDirection.asc,
      }];
    }),

    actions: computed(
      () => {
        switch (activeTab.value) {
          case 'paid-ups':
            return [
              {
                key: 'add',
                icon: 'add',
                label: 'Добавить данные',
                id: 'modal_debtor_finance_tab_paid_ups_tab_add_data_btn',
                types: [ActionType.side],
                handler: async () => {
                  const response = await financeDialogs.showCreateDialog();
                  if (response.status) {
                    await refetch();
                    dispatchSignal(SignalType.debtorsUpdated);
                  }
                },
              },
              {
                key: 'remove',
                icon: 'close',
                types: [ActionType.record],
                label: 'Удалить',
                id: 'modal_debtor_finance_tab_paid_ups_tab_delete_record_btn',
                handler: async ({ selectedItems: [itemIndex] }: any) => {
                  if (!financeDataModel.value) {
                    return;
                  }
                  const record = financeDataModel.value.paid_ups_data.data[itemIndex - 1];
                  const response = await financeDialogs.showDeleteDialog(
                    record.parts.map(({ finance_data }) => finance_data as number),
                  );
                  if (response.status) {
                    await refetch();
                    dispatchSignal(SignalType.debtorsUpdated);
                  }
                },
              },
            ];
          case 'debts':
            return hasWritePermission.value && debtor.value?.disable_calculations === true ? [
              {
                key: 'edit',
                icon: 'pencil',
                types: [ActionType.record],
                label: 'Редактировать',
                id: 'modal_debtor_finance_tab_debts_tab_edit_record_btn',
                handler: async ({ selectedItems: [itemId] }: any) => {
                  editingItem.value = itemId;
                },
                check: ({ record: { id } }) => editingItem.value !== id,
              },
              {
                key: 'done',
                icon: 'check',
                label: 'Сохранить',
                types: [ActionType.record],
                async handler({ selectedItems: [itemId] }) {
                  const model = records.value.find((record: any) => record.id === itemId);

                  const { response } = await store.dispatch('finance/updateDebtorDebtData', {
                    id: itemId,
                    model,
                  });

                  model.prev_value = response.start_balance;

                  editingItem.value = null;
                },
                check: ({ record: { id } }) => editingItem.value === id,
              },
            ] : [];
          case 'penalties':
            return hasWritePermission.value && debtor.value?.disable_calculations === true ? [
              {
                key: 'edit',
                icon: 'pencil',
                types: [ActionType.record],
                label: 'Редактировать',
                id: 'modal_debtor_finance_tab_penalties_tab_edit_record_btn',
                handler: async ({ selectedItems: [itemId] }) => {
                  console.log(records.value[itemId]);
                  editingItem.value = records.value[itemId].pk;
                },
                check: ({ record: { pk } }) => editingItem.value !== pk,
              },
              {
                key: 'done',
                icon: 'check',
                label: 'Сохранить',
                types: [ActionType.record],
                handler: async ({ selectedItems: [itemId] }) => {
                  const item = records.value[itemId];
                  await store.dispatch('finance/updateDebtorPenaltiesData', {
                    id: item.pk,
                    model: records.value.find((record: any) => record.pk === item.pk),
                  });

                  editingItem.value = null;
                },
                check: ({ record: { pk } }) => editingItem.value === pk,
              },
            ] : [];
          default:
            return [];
        }
      },
    ),
  });

  watch(debtor, async (d) => {
    if (d) {
      await refetch();
    }
  });

  watch(activeTab, (v) => {
    editingItem.value = null;
  });

  watch(agreementId, async (id) => {
    if (id) {
      agreementChange = true;
      await refetch();
      agreementChange = false;
    }
  });

  return {
    records,
    columns,
    actions,
    fullData,
    isLoading,
    agreementId,
    agreements,
    sort,
    editingItem,
    errorsMap,
  };
};
