import { fieldGroup, fieldsUniter } from '@/components/automation/pretrial/tabs/utils';
import { ActiveFormFieldType } from '@/hooks/useActiveForm';
import { Ref } from 'vue';
import { PretrialAutomationModel } from '@/components/automation/pretrial/tabs/types';

export const getCommonFields = (model: Ref<PretrialAutomationModel>) => {
  return fieldsUniter('common', [
    {
      key: 'debt_notification_threshold',
      field: 'debt_notification_threshold',
      type: ActiveFormFieldType.range,
      options: {
        min: 0,
        max: 1000000,
        withManualInput: true,
      },
      ionFieldGroup: fieldGroup('common'),
    },
  ]);
};
