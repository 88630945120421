import { useCompanySettingsSecure } from '@/hooks/useCompanySettingsSecure';
import { ApiResponse } from '@/service/api';
import { Ref } from 'vue';
import { CompanyOptions } from '@/pages/exchange/integration/dialogs/integrationGosuslugi/types';

export const useAuthFetch = (
  companyId: Ref<number>,
  user: Ref<{ id: number } | null>,
  esiaAuthed: Ref<boolean>,
  esiaId: Ref<string>,
  activeCompany: Ref<string>,
  companyOptions: Ref<CompanyOptions>,
  isLoading: Ref<boolean>,
) => {
  const {
    fetchEsiaUserSetting,
    fetchUserRolenames,
    fetchUserRolename,
    deleteUserSetting,
  } = useCompanySettingsSecure();

  const fetchAuth = async () => {
    isLoading.value = true;

    const { status, response } = await fetchEsiaUserSetting({
      company_id: companyId.value,
      owner: user.value?.id as number,
    });
    if (status) {
      if (response) {
        esiaAuthed.value = true;
        esiaId.value = response.data.esia_id;
      }
    }
    isLoading.value = false;
  };

  const fetchRoles = async () => {
    isLoading.value = true;
    const payload = {
      company_id: companyId.value,
      owner: user.value?.id as number,
    };
    const [
      rolenamesResponse,
      rolenameResponse,
    ] = await Promise.all([
      fetchUserRolenames(payload),
      fetchUserRolename(payload),
    ]);
    if (!rolenamesResponse.status || !rolenameResponse.status) {
      return;
    }
    activeCompany.value = rolenameResponse.response?.data.rolename;
    companyOptions.value = rolenamesResponse.response?.data.rolenames.map(
      ({ short_rolename, display_text }) => ({
        label: display_text,
        value: short_rolename,
      }),
    ) ?? [];
    isLoading.value = false;
  };

  const deleteUserAuthData = async () => {
    const results = await Promise.all([
      deleteUserSetting({
        company_id: companyId.value,
        owner: user.value?.id as number,
        key: '_esia_id',
      }),
      deleteUserSetting({
        company_id: companyId.value,
        owner: user.value?.id as number,
        key: 'active_esia_rolename',
      }),
      deleteUserSetting({
        company_id: companyId.value,
        owner: user.value?.id as number,
        key: 'esia_rolenames',
      }),
      deleteUserSetting({
        company_id: companyId.value,
        owner: user.value?.id as number,
        key: 'active_mos_ru_rolename',
      }),
    ]);
    const totalStatus = results.every(({ status }) => status);
    if (totalStatus) {
      esiaAuthed.value = false;
      companyOptions.value = [];
    }
    return {
      status: totalStatus,
      response: null,
    } as ApiResponse<any>;
  };

  return {
    deleteUserAuthData,
    fetchAuth,
    fetchRoles,
  };
};
