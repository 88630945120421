import { useStore } from 'vuex';
import { ProductionType } from '@/hooks/useConstructor';
import { ApiResponse, ListingRequestSource } from '@/store/modules/api';
import {
  CompanyModuleType,
  FetchDebtorModel,
  FetchDebtorResponse,
  FetchDebtorsModel,
  FetchDebtorsResponse,
} from '@/pages/debtors/_module/useDebtorsActions';
import { Company, Region } from '@/hooks/useCompanies';
import {
  VehiclePaymentCode,
  VehiclePaymentSumType,
} from '@/components/dialog/dialogs/debtor/tabs/finance/useTable/utils';
import { DebtorIcon } from '@/store/modules/debtors';
import { StandartizeAddress, StandartizeAddressColor } from '@/service/standartize/types';

export type DebtorSubstatusDetails = {
  index: number;
  text: any;
  type: string;
}
export type DebtorSubstatus = {
  substatus: string;
  updated_at: string;
  data: DebtorSubstatusDetails |string| null;
}
export type DebtorStatus = {
  id: number;
  status: string;
  updated_at: string;
  substatus: Array<DebtorSubstatus> ;
  production_type: ProductionType;
}
export type DebtorStatusNew = {
  status: string;
  substatus: Array<DebtorSubstatus> ;
}

export type Writoe = {
  id: number;
  debtor: number;
  serial_number: string;
  case_number: string;
  case_date: string;
  end_date: string;
  termination_ground: string;
  bailiff_full_name: string;
  bailiff_phone_number: string;
  court: string;
  amount: string;
  executive_amount: string;
  default: boolean;
}

export type Bailiff = {
  pk: number;
  name: string;
  lead_FIO: string;
  address: string;
  district: number;
  fax: string;
  phone: string;
  phone_info: string;
  schedule: string;
}

export type IFNS = {
  code: string;
  created_at: string;
  updated_at: string;
  name: string;
  address: string;
  inn: string;
  kpp: string;
  payee: string;
  bank: string;
  bik: string;
  rs: string;
  ks: string;
  reg_code: string;
  phone: string;
  description: string;
}

export type VehicleTripPlace = {
  id: number;
  clear_name: string;
  default_price: string;
  name: string;
  operator: number;
  operator_name: string;
}

export type VehicleTrip = {
  id: number;
  cost: string;
  date: string;
  lane: number;
  payment_type: VehiclePaymentCode;
  place: VehicleTripPlace;
  transaction: string;
  transaction_date: string;
}

export type VehicleTripsTotal = Record<VehiclePaymentSumType, number>

export type VehicleInfo = {
  id: number;
  model: number;
  model_name: string;
  tc_type: number;
  tc_type_name: string;
}

export type Vehicle = {
  id: number;
  grz: string;
  vin: string;
  ctc: string;
  ptc: string;
  ctc_date: string;
  ptc_date: string;
  info: VehicleInfo;
  region: Region;
  trips: VehicleTrip[];
  trips_total: VehicleTripsTotal;
}

export type OrganizationBankruptData = {
  case_number: string;
  accept_statement_date: string;
  realization_property_date: string;
  bankruptcy_completion_date: string;
  decision_date: string;
  sources: 'efrsb' | 'kad';
}

export type DebtorMainProfileOrganization = {
  id: number;
  inn: string;
  kpp: string;
  director_info: {
    director: string | null;
  } | null;
  legal_address: string;
  mail_address: string;
  physical_address: string;
  name: string;
  name_full: string;
  name_short: string;
  ogrn: string;
  ogrn_data_reg: string;
  egrul_extract: string;
  is_bankrupt: boolean;
  bankrupt_data: Array<OrganizationBankruptData>;
  emails: Array<string | { email: string }>;
  treasury_department_address: string;
  treasury_department_name: string;
}

export type DebtorPhoneNumber = {
  id: number;
  dialing_attempts?: number;
  debtor_main_profile?: Debtor['debtor_main_profile']['id'] | null;
  debtor_tenant_profile?: number | null;
  is_valid: boolean|null;
  number: string;
}

type DebtorMainProfileCommonFields = {
  emails: Array<string | { email?: string }>;
  id: number;
  debtor_id: number;
  address: string;
  address_st: string;
  bailiff: Bailiff;
  ifns: IFNS;
  created_at: string;
  updated_at: string;
  production_type: ProductionType;
  full_name_orig: string;
  full_name: string;
  registration_address: string;
  phone_number: string;
  debtor: number;
  arbitration_court_place: number;
  regional_court_place: number;
  magistrate_court_place: number;
  enrichment_date: string|null;
  enrichment_success: boolean;
  phone_numbers: DebtorPhoneNumber[];
  cadnum: string;
  addr_st: Record<string, any> & {
    is_verified_cadnum: boolean;
    is_verified_mir_court: boolean;
    is_verified_reg_court: boolean;
    is_verified_result: boolean;
    color: StandartizeAddressColor;
    magistrate_court_place: number|null;
    regional_court_place: number|null;
  };
  registration_address_obj: Record<string, any> & {
    is_verified_cadnum: boolean;
    is_verified_mir_court: boolean;
    is_verified_reg_court: boolean;
    is_verified_result: boolean;
    color: StandartizeAddressColor;
    magistrate_court_place: number|null;
    regional_court_place: number|null;
  };
  use_contractual_magistrate_court_place: boolean;
  use_contractual_regional_court_place: boolean;
  use_contractual_arbitration_court_place: boolean;
  send_to_fssp_date: string;
  status: string;
}

export type DebtorPersonRegistrationObj = StandartizeAddress & {
  result: string|null;
  postal_code: string|null;
  source: string|null;
}

export type DebtorPersonMainProfile = DebtorMainProfileCommonFields & {
  organization: null;
  social_networks: Record<string, string>;
  registration_address_st?: string;
  registration_address_obj?: DebtorPersonRegistrationObj;
  is_standardized: boolean;
  area: string;
}

export type DebtorOrganizationMainProfile = DebtorMainProfileCommonFields & {
  organization: DebtorMainProfileOrganization;
  is_standardized: boolean;
}

export type DebtorMainProfile = DebtorPersonMainProfile | DebtorOrganizationMainProfile;

export type TenantRelationship = string;

export type TenantSource = {
  egrn: string;
  house_register_document: string;
  enrichment: string;
  billing: string;
}
export type TenantInnInfoPosition = 'general_director' | 'founder';

export type TenantInnInfo = {
  created_at: string;
  disqualified_from: string | null;
  disqualified_to: string | null;
  inn: string;
  invalid_date: string | null;
  is_disqualified: boolean | null;
  ip_registration_date: string | null;
  is_self_employed: boolean | null;
  is_valid: boolean | null;
  positions: { [key: string]: Array<TenantInnInfoPosition> };
  updated_at: string;
}

export type TenantBankruptData = {
  tenant_name: string;
  case_number: string | null;
  accept_statement_date: string | null;
  decision_date: string | null;
  realization_property_date: string | null;
  bankruptcy_completion_date: string | null;
  sources: Array<'efrsb' | 'kad'>;
};

export type TenantInheritanceCase = {
  id: number;
  created_at: string;
  updated_at: string;
  case_number: string;
  death_date: string|null;
  tenant: number;
  notary_name: string|null;
};

export type Tenant = {
  id: number;
  full_name: string;
  bankrupt_data: Array<TenantBankruptData>;
  billing_date: string|null;
  birth_date: string | Date | null;
  birth_place: string;
  citizenship: string;
  created_at: string;
  date_of_passport_issue: string | Date | null;
  debtor: number;
  enrichment_sources: { fns: string; efrsb: string };
  full_name_orig: string;
  inheritance_cases: Array<TenantInheritanceCase>;
  inn: string;
  inn_info: TenantInnInfo | null;
  inn_searched: boolean;
  inn_sources: { fns: string; register: string };
  is_bankrupt: boolean;
  num_of_passport: string | null;
  package_id: any;
  parsing_status: string | null;
  passport_is_valid: boolean;
  passport_issued_by: string;
  production_type: ProductionType;
  registration: number | null;
  registration_address: string | null;
  registration_address_st: string | null;
  registration_date: string | Date | null;
  relationships: Array<TenantRelationship>;
  self_employed: boolean|null;
  snils: number|null;
  snils_is_valid: boolean|null;
  sources: TenantSource;
  updated_at: string;
  verified: boolean;
  phone_numbers: DebtorPhoneNumber[];
  where_left: string;
  deregistration_date: string;
  registration_address_obj: Record<string, any> & {
    is_verified_cadnum: boolean;
    is_verified_mir_court: boolean;
    is_verified_reg_court: boolean;
    is_verified_result: boolean;
    color: StandartizeAddressColor;
    magistrate_court_place: number|null;
    regional_court_place: number|null;
  };
}

export type DebtData = {
  id: number;
  production_type: ProductionType;
  start_date: string;
  end_date: string;
  value: string;
  prev_value: string;
  is_paid_up: boolean;
  debtor_data: number;
  trip?: {
    transaction: string;
    place: {
      name: string;
      operator: {
        name: string;
      };
    };
  };
}

export type PenaltyData = {
  pk: number;
  start_date: string;
  value: string;
}

export type DebtorCourtType = 'mirsud' | 'rajsud' | 'arbitr';

export type DebtorFields = {
  pk: number;
  debtor: {
    pk: number;
    company_id: string;
    personal_account: string;
    debtor_status: Array<DebtorStatus>;
    pretrial_state: number;
    notification_lock: number|null;
    agreement: {
      created_at: string;
      extra_agreements: {
        created_at: string;
        number: number;
        amount: number;
        valid_from: string;
        valid_until: string;
      }[];
      id: number;
      valid_from: string;
      valid_until: string;
    }|null;
    assigned_users: {
      id: number;
      user: number;
      debtor: number;
      created_at: string;
      updated_at: string;
    }[];
  };
  debtor_tenant_profiles: Array<Tenant>;
  vehicle?: Vehicle|null;
  created_at: string;
  status: string;
  accruals_data: Array<any>;
  accrual_columns: Array<string>;
  paid_ups_data: Array<any>;
  paid_up_columns: Array<string>;
  debts_data: Array<DebtData>;
  penalties_data: Array<PenaltyData>;
  total_executive_amount: number;
  actual_debt: number | string;
  actual_debt_length: number | string;
  total_debt: number;
  total_penalty: number;
  total_accrual: string;
  total_paid_up: string;
  total_fee: number;
  clean_address: string;
  total_total_debt: number;
  total_recalculations: string;
  'debtor.pk': string;
  production_type: ProductionType;
  writ_of_execution: Writoe;
  billing_upload: string|null;
  billing_download: string|null;
  lack_status: boolean;
  okrug_short: string;
  tracker_court_types: Array<DebtorCourtType>;
  complaint_status: 'Зарегистрировано'|'Документ приобщён к судебному делу'|'Передан на судебный участок'|null;
  court_places: string[];
  is_decision_inexpedient: boolean;
  last_sync_date: boolean;
  convicting_date_from: Date;
  convicting_date_to: Date;
}
export type DebtorOrganization = DebtorFields & {
  debtor_main_profile: DebtorOrganizationMainProfile;
}

export type DebtorPerson = DebtorFields & {
  debtor_main_profile: DebtorPersonMainProfile;
  _tenants?: Tenant[];
}

export type MetaOfDebtorStatus = {
  substatuses: Array<DebtorSubstatus['substatus']>;
  smsStatus: DebtorSubstatus['substatus']|null;
  voiceStatus: DebtorSubstatus['substatus']|null;
  voiceStatusText: string|null;
  emailStatus: DebtorSubstatus['substatus']|null;
  isFeesShareholdersAwaitPaid: boolean;
  isFeesShareholdersPaid: boolean;
  isFeesAwaitPaid: boolean;
  isFeesAwaitRepaid: boolean;
  isFeesPaid: boolean;
  isStatementOrdered: boolean;
  isStatementReady: boolean;
  isCadnumNotFound: boolean;
  isCadnumHasNoRights: boolean;
  isStatementError: boolean;
  isStatementReceived: boolean;
  isReadyToCourt: boolean;
}

export type Debtor = (
    DebtorOrganization | DebtorPerson
) & {
  status_updated_at?: Date|null;
  icons?: DebtorIcon[];
  meta?: MetaOfDebtorStatus;
  bankrupts_data?: Array<TenantBankruptData>;
  disable_calculations?: boolean;
};

export type FetchDebtorStatusEntriesModel = {
  debtorId: Debtor['pk'];
  productionType: ProductionType;
}
export type FetchDebtorStatusEntriesResponse = {
  entries: Array<DebtorStatus>;
  activeEntryKey: DebtorStatus['status'];
}

export type UpdateDebtorStatusEntriesModel = {
  id: Debtor['pk'];
  productionType: ProductionType;
  companyId?: Company['id'];
  entries: Array<DebtorStatus|DebtorStatusNew>;
  activeEntryKey?: DebtorStatus['status'];
}

// @TODO уточнить
export type UpdateDebtorStatusEntriesResponse = any;

export type Phone = Omit<DebtorPhoneNumber, 'id'|'debtor_main_profile'|'debtor_tenant_profile'> & { id?: number };

export type DebtorPersonModel = Pick<
  DebtorPersonMainProfile,
  'full_name' | 'address' | 'registration_address' | 'emails'
  > & {
  personal_account: string;
  social_networks: { key: string|null; value: string|null }[];
  phone_numbers: Phone[];
  tenant_phone_numbers: Phone[];
  tenantId?: Tenant['id'];
  isOrganization: false;
};

export type DebtorOrganizationModel = Pick<
  DebtorMainProfileOrganization,
  'name' | 'legal_address' | 'physical_address' | 'mail_address' | 'emails'
  > & {
  personal_account: string;
  phone_numbers: Phone[];
  isOrganization: true;
};

export type DebtorDetailsOrganizationModel = {
  name_full: string;
  ogrn: string;
  inn: string;
  kpp: string;
  ogrn_data_reg: string;
  full_name: string;
}

export type DebtorModel = DebtorPersonModel | DebtorOrganizationModel

export type UpdateDebtorMainProfileModel = {
  id: DebtorMainProfile['id'];
  productionType: ProductionType;
  debtorId: Debtor['pk'];
  model: Omit<DebtorModel, 'phone_numbers'>;
}

export type UpdateDebtorMainProfileResponse = any;

export type CreateTenantModel = {
  model: Tenant;
  productionType: ProductionType;
  debtor: Debtor['pk'];
}
export type CreateTenantResponse = any;

export type UpdateTenantModel = {
  id: Tenant['id'];
  model: Tenant;
  productionType: ProductionType;
}
export type UpdateTenantResponse = any;

export type RemoveTenantModel = {
  id: Tenant['id'];
}
export type RemoveTenantResponse = any;

export type UpdateInnModel = {
  module_type: CompanyModuleType;
  production_type: ProductionType;
  payload: Array<Debtor['pk']>;
  company: Company['id'];
}

export type UpdateInnResponse = {
  task_uuid: string;
}

export type CreateWritoeModel = {
  debtor_id: Debtor['pk'];
  company_id: Company['id'];
  case_number: string;
  serial_number: string;
  court: string;
}

export type CreateWritoeResponse = any;

export type RefreshWritoeModel = {
  company_id: number;
  id: number;
}

export type RefreshWritoeResponse = any;

export type FetchFsspModel = ListingRequestSource<{
  // id: Debtor['pk'];
  debotor_ids: Debtor['pk'][];
  debt_nature: string;
}>

export type FetchFsspResponse = any;

export type UpdateDefaultWritoeModel = {
  company_id: number;
  id: number;
}

export type UpdateDefaultWritoeResponse = any;

export type UpdateDebtorsStatusesEntriesModel = {
  productionType: ProductionType;
  filters: Record<string, any>;
  entries: Array<DebtorStatus|DebtorStatusNew>;
  activeEntryKey?: DebtorStatus['status'];
}

export const SOCIAL_NETWORKS = [
  ['telegram', 'Telegram', 'https://t.me/username'],
  ['vk', 'Вконтакте', 'https://vk.com/id12345'],
  // ['mail.ru', 'Mail.ru', ''],
  // ['facebook', 'Facebook'],
  ['instagram', 'Instagram', 'https://instagram.com/username'],
  ['ok', 'Одноклассники', 'https://ok.ru/username'],
  // ['viber', 'Viber'],
  ['whatsapp', 'Whatsapp', 'https://wa.me/1XXXXXXXXXX'],
].map(([key, name, hint]) => ({
  icon: `sn-${key}`,
  key,
  name,
  hint,
}));

export const prepareSocialLinkToPayload = (link: string) => link.replace(/^https?:\/\//, '');
export const prepareSocialLinkToModel = (link: string) => `https://${link}`;

export const useDebtors = () => {
  const store = useStore();

  const fetchDebtors = async (
    model: FetchDebtorsModel,
  ): Promise<ApiResponse<FetchDebtorsResponse>> => {
    const { status, response } = await store.dispatch('debtors/fetchDebtors', model);
    return { status, response };
  };

  const fetchDebtor = async (
    model: FetchDebtorModel,
  ): Promise<ApiResponse<FetchDebtorResponse>> => {
    const { status, response } = await store.dispatch('debtors/fetchDebtor', model);
    return { status, response };
  };

  const fetchDebtorStatusEntries = async (
    payload: FetchDebtorStatusEntriesModel,
  ): Promise<ApiResponse<FetchDebtorStatusEntriesResponse>> => {
    const { status, response } = await store.dispatch('debtors/fetchDebtorStatusEntries', payload);
    return { status, response };
  };

  const updateDebtorStatusEntries = async (
    payload: UpdateDebtorStatusEntriesModel,
  ): Promise<ApiResponse<UpdateDebtorStatusEntriesResponse>> => {
    const { status, response } = await store.dispatch('debtors/updateDebtorStatusEntries', payload);
    return { status, response };
  };

  const updateDebtorMainProfile = async (
    payload: UpdateDebtorMainProfileModel,
  ): Promise<ApiResponse<UpdateDebtorMainProfileResponse>> => {
    const { status, response } = await store.dispatch('debtors/updateDebtorMainProfile', payload);
    return { status, response };
  };

  const createTenant = async (
    payload: CreateTenantModel,
  ): Promise<ApiResponse<CreateTenantResponse>> => {
    const { status, response } = await store.dispatch('debtors/createTenant', payload);
    return { status, response };
  };

  const updateTenant = async (
    payload: UpdateTenantModel,
  ): Promise<ApiResponse<UpdateTenantResponse>> => {
    const { status, response } = await store.dispatch('debtors/updateTenant', payload);
    return { status, response };
  };

  const removeTenant = async (
    payload: RemoveTenantModel,
  ): Promise<ApiResponse<RemoveTenantResponse>> => {
    const { status, response } = await store.dispatch('debtors/removeTenant', payload);
    return { status, response };
  };

  const updateInn = async (
    model: UpdateInnModel,
  ): Promise<ApiResponse<UpdateInnResponse>> => {
    const { status, response } = await store.dispatch('debtors/updateInn', model);
    return { status, response };
  };

  const createWritoe = async (
    model: CreateWritoeModel,
  ): Promise<ApiResponse<CreateWritoeResponse>> => {
    const { status, response } = await store.dispatch('debtors/createWritoe', model);
    return { status, response };
  };

  const refreshWritoe = async (
    model: RefreshWritoeModel,
  ): Promise<ApiResponse<RefreshWritoeResponse>> => {
    const { status, response } = await store.dispatch('debtors/refreshWritoe', model);
    return { status, response };
  };

  const fetchFssp = async (
    model: FetchFsspModel,
  ): Promise<ApiResponse<FetchFsspResponse>> => {
    const { status, response } = await store.dispatch('debtors/fetchFssp', model);
    return { status, response };
  };

  const setDefaultWritoe = async (
    model: UpdateDefaultWritoeModel,
  ): Promise<ApiResponse<UpdateDefaultWritoeResponse>> => {
    const { status, response } = await store.dispatch('debtors/setDefaultWritoe', model);
    return { status, response };
  };

  const updateDebstorsStatusesEntries = async (
    model: UpdateDebtorsStatusesEntriesModel,
  ) => store.dispatch('debtors/UpdateDebtorsStatusesEntries', model);

  return {
    updateInn,
    createTenant,
    updateTenant,
    removeTenant,
    fetchDebtors,
    fetchDebtorStatusEntries,
    updateDebtorStatusEntries,
    updateDebstorsStatusesEntries,
    fetchDebtor,
    updateDebtorMainProfile,
    createWritoe,
    refreshWritoe,
    fetchFssp,
    setDefaultWritoe,
  };
};
